'use client';
import type { FunctionComponent } from 'react';
import { FieldError } from 'react-hook-form';

type Props = {
    error?: FieldError | { message: string };
};

const FormError: FunctionComponent<Props> = ({ error }) => {
    return <>{error && <div className="mt-2 text-left text-sm text-danger">{error?.message}</div>}</>;
};

FormError.displayName = 'FormError';

export default FormError;
