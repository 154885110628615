'use client';

import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'src/i18n/client';

type Props = {
    locale: string;
    title: string;
    description: string;
    href?: string;
    label?: string;
};

export default function InfoPanel({ locale, title, description, href, label }: Props) {
    const { t } = useTranslation(locale, 'common');
    return (
        <div className="mx-auto text-center md:w-4/5 xl:w-3/5">
            <div className="flex flex-col gap-4">
                <h1 className="text-4xl lg:text-6xl">{t(title)}</h1>

                <p className="my-4 text-sm md:text-base">{t(description)}</p>

                {href && (
                    <Link href={href} className="btn btn-secondary btn-sm md:my-4">
                        {label && t(label)}
                    </Link>
                )}
            </div>
        </div>
    );
}
